import * as React from 'react'
import queryString from 'query-string'

import CanvasHouseViewer from '../../components/canvasHouseViewer/canvasHouseViewer'

const IndexPage = () => {
	let renoworks_id: string | null = null
	if (typeof window !== 'undefined') {
		const queryStrings = queryString.parse(location.search)
		renoworks_id = (queryStrings.renoworks_id as string) || null
	}

	return (
		<CanvasHouseViewer
			renoworksId={renoworks_id}
			// propertySpec={currentProperty.propertySpec}
		/>
	)
}

export default IndexPage
